<!--
	Created by xiaoqiang on 2023/10/12.
-->
<template>
  <van-row>
    <s-header name="沈阳东溪网络科技有限公司退货协议书" :back="'/user'"></s-header>
    <div  class="mProContent">
      <h2 class="textCenter">沈阳东溪科技网络有限公司<br>退货协议书</h2>
      <p>一、目的与范围</p>
      <p>为规范公司的退货流程，提高退货处理效率，减少退货损失，特制定本制度。</p>
      <p>本制度适用于公司所有业务流程中的退货操作。</p>
      <p>二、退货流程</p>
      <p>1.客户发起退货请求</p>
      <p>客户通过电话、邮件、在线客服等方式向公司客服发起退货请求，客服应当及时回复客户并告知退货流程。客户需提供订单号、商品名称、数量、退货原因等信息。</p>
      <p>2.客服审核退货请求</p>
      <p>客服收到客户的退货请求后，应当审核退货请求的合理性。如果退货原因符合公司规定的退货政策，则将退货申请转交仓库管理员。</p>
      <p>3.仓库管理员审核退货请求</p>
      <p>仓库管理员接收到客服转交的退货请求后，应当审核退货请求的合理性，并确认退货商品的数量、质量、型号是否与订单一致。如果符合要求，则将退货商品入库，并向客服发送退货入库通知。</p>
      <p>4.财务部门审核退款请求</p>
      <p>财务部门在接收到客服发送的退款申请后，应当审核申请的真实性，并将符合退款政策的款项退回至客户账户。</p>
      <p>三、退货政策</p>
      <p>1.在公司规定的退货期内，客户可以申请退货。</p>
      <p>2.退货的商品必须是公司销售的正品，且商品数量、质量、型号与订单一致。</p>
      <p>3.退货商品的包装应当完好无损，未经使用或人为损坏。</p>
      <p>4.退货商品应当连同所有配件、标签、说明书、赠品等一并退回。</p>
      <p>5.如果退货原因是因为公司的过错导致的，则公司应当承担退货产生的费用。</p>
    </div>
    <van-row v-if="false" class="color24 size14 paddBott60 paddTop30 textCenter"><span style="font-family:Arial;">沈阳东溪网络科技</span>2023版权所有&nbsp;
      <a href="https://beian.miit.gov.cn/" class="colorBlue" target="_self">辽ICP备2023004399号</a>
    </van-row>
  </van-row>
</template>

<script>
  import sHeader from '@/components/SimpleHeader'
  export default {
    name: "agreement",
    components: {sHeader},
    data() {
      return {};
    },
    created() {

    },
    mounted() {
    },
    methods: {},
    //生命周期 - 更新之后",数据模型已更新,html模板已更新
    updated() {

    },
  }
</script>

<style scoped>
  .mProContent {
    background-color: #fff;
    color: #666;
    font-family: "微软雅黑", Helvetica, "黑体", Arial, Tahoma;
    font-size: 14px;
    padding: 20px;
    word-break: break-word;
    padding-top: 60px;
  }
  p{
    text-indent: 28px;
  }
</style>
